/* .ant-btn-primary {
  background-color: var(--primary-color);
  color: var(--text-color);
} */

/*   [data-theme="mesc"].ant-btn-primary {
    background-color: var(--primary-color);
    color: var(--text-color);
  }
  
  [data-theme="dark"].ant-btn-primary {
    background-color: var(--primary-color);
    color: var(--text-color);
  } */

/* latin-ext */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZ2IHSeH.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIHQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: normal;
}

.primary-text-color {
  color: var(--primary-text-color) !important;
  font-weight: 600;
}

.secondary-text-color {
  color: var(--secondary-text-color) !important;
  font-weight: 400;
}

.text-title {
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5625em;
  line-height: 30px;
}

.notification-title {
  font-style: bold;
  font-weight: 700;
}

.text-sub {
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 20px;
}

.text-header {
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25em;
  line-height: 22px;
}

.text-dashboard {
  font-family: var(--font-family) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 21px;
  font-weight: bold;
}

/* LAYOUT */

.loading-grid {
  display: grid;
  place-items: center;
  height: 100vh;
}

.layout-container {
  display: grid;
  grid-template-rows: 100%;
  gap: 0px;
  transition: all 1s ease;
}

.layout-main {
  width: 100%;
  height: 100vh;
  background: none;
}

.layout-content {
  max-width: 100%;
  overflow: hidden;
  background: var(--background-secondary) !important;
}

.layout-menu {
  max-width: 100%;
  background: var(--background-secondary) !important;
}

.ant-menu-submenu-arrow {
  color: var(--primary-color) !important;
}

.layout-header {
  width: 100%;
}

.layout-header {
  background-color: var(--background);
}

.sub-title-larger {
  color: var(--primary-text-color);
}

.sub-title-small {
  color: var(--secondary-color);
}

.card_default {
  box-shadow: var(--box-shadow);
  background-color: var(--background);
}

/* .card_category {
  box-shadow: var(--box-shadow);
  background-color: var(--background);
  border-radius: var(--default-radius);
} */

.card_accordion {
  box-shadow: var(--box-shadow);
  background: var(--background);
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.padding-list {
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.dashboard .dashboard-container {
  box-shadow: var(--box-shadow);
  background-color: var(--background);
}

.dashboard-select-container {
  margin-top: -0.5em;
}

.ant-layout-sider {
  background: var(--background) !important;
}

.search-header .ant-input-affix-wrapper {
  font-size: 1.4em;
  border: none;
  color: var(--primary-color);
  background: var(--background) !important;
}

.search-header .ant-input {
  border-radius: var(--default-radius) !important;
  padding: 0px 15px !important;
}

.ant-drawer-content {
  background: var(--background) !important;
}

/* NOFIFICATION */

.blur {
  filter: blur(1px);
}

/* HEADER */

.badge-header:hover {
  cursor: pointer;
}

.badge-header svg:hover {
  width: 22px !important;
  height: 22px !important;
}

.ant-scroll-number,
.ant-badge-dot,
.ant-badge-status-green {
  margin-top: 20px;
}

.search-header .ant-input-prefix {
  font-size: 140%;
  margin-right: 14px;
}

.select-header * {
  background: var(--background) !important;
  border: none !important;
}

.select-header .ant-select-selection-item {
  color: var(--primary-text-color) !important;
}

.header-content .ant-select-selection-item {
  font-weight: bold;
  font-size: 1.2em;
}

.brand {
  color: var(--text-color);
}

.logo {
  height: 60px;
}

.div-logo {
  background: var(--background);
  border-radius: var(--default-radius);
  margin-right: 2px;
  padding: 2px;
  /* text-align: center; */
}

.logo-email {
  color: var(--secondary-color);
}

.menu-user li:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.ant-popover-inner {
  background: var(--background) !important;
}

.select-header .ant-select div {
  background: var(--background) !important;
}

.header-drop-ul li {
  margin-top: 10px;
}

/* collapse */

.ant-collapse {
  border-radius: var(--default-radius-layout) !important;
  background: var(--background);
}

.ant-collapse-expand-icon {
  display: none !important;
}

.ant-collapse-header {
  padding-top: 20px !important;
}

.ant-btn-primary:not(:disabled):active,
.ant-btn-primary:not(:disabled):hover {
  background-color: var(--primary-color) !important;
}

.ant-collapse-header-text {
  font-style: var(--font-style) !important;
  font-weight: 700 !important;
  margin-left: 10px !important;
}

.row-img {
  /* margin-bottom: 15px;
    width: 100%; */
  border-radius: var(--default-radius);
}

.row-img img {
  /* margin-top: 5px;
    height: 25px !important;
    height: 25px !important; */
}

/* MENU */

.ant-tree-list-holder-inner {
  background-color: var(--background) !important;
  color: var(--secondary-color);
}

.silder-menu {
  margin: 16px;
  padding: 8px 0px;
  box-shadow: var(--box-shadow);
  border-radius: var(--default-radius-layout);
  background-color: var(--background) !important;
}

.content-main {
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btn-right {
  float: right;
  margin-top: 30px;
}

.ant-modal-content {
  box-shadow: var(--box-shadow);
  border-radius: var(--default-radius-layout) !important;
  background-color: var(--background) !important;
}

.ant-modal-title {
  background-color: var(--background) !important;
}

.default-padding {
  padding: 16px;
}

.ant-layout-header {
  padding: 0em 2em 0em 1em;
}

.ant-layout-header.layout-header {
  height: 93px;
  background: var(--background);
  box-shadow: var(--box-shadow);
  padding-top: 12px;
}

.ant-menu-submenu-open {
  padding-bottom: 1em;
  background: var(--background) !important;
}

.-title-content {
  margin-left: 10px;
}

.div-logout-content {
  text-align: left;
  padding-left: 2em;
  width: 100%;
  bottom: 35px;
  position: absolute;
  color: var(--secondary-color);
}

.div-logout-content-close {
  text-align: center;
  width: 100%;
  bottom: 40px;
  position: absolute;
  color: var(--secondary-color);
}

.div-logout {
  border: none !important;
  color: var(--secondary-color) !important;
  padding: 0px;
  background-color: none !important;
  box-shadow: none !important;
}

.div-logout:hover {
  color: var(--primary-color) !important;
}

.div-footer {
  line-height: 20px;
  font-size: 11px;
  padding-left: 5px;
}

@media (max-width: 800px) {
  .margin-top-mobile {
    margin-top: 1em;
  }
}

.menu-content {
  margin-top: 10px;
  border-radius: var(--default-radius);
  background: var(--background) !important;
}

.ant-menu {
  background: var(--background) !important;
}

.ant-menu-inline-collapsed .-title-content {
  display: none;
}

.ant-menu-item-icon,
.-item-icon {
  width: 20px;
}

.ant-menu-inline {
  background: var(--background) !important;
}

.ant-menu-title-content {
  color: var(--secondary-color) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--background) !important;
}

.silder-menu .ant-menu-item-selected .ant-menu-title-content {
  width: auto;
  color: var(--primary-color) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  margin: 0;
}

.silder-menu li+.ant-menu-item-selected {
  border-radius: 0px;
  width: 100%;
  border-right: 3px solid var(--primary-color) !important;
}

.silder-menu .ant-menu-item-selected {
  border-radius: 0px;
  width: 100%;
  border-right: 3px solid var(--primary-color) !important;
}

.ant-menu-submenu .ant-menu-submenu-inline {
  padding: 0px;
  color: var(--primary-color) !important;
}

.collapse:hover {
  cursor: pointer;
}

.collapse {
  background: var(--primary-color);
  height: 35px;
  padding: 7px;
  float: right;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.collapse-open {
  width: 38px;
  transform: rotate(0deg);
  transition: all 0.9s ease-in;
}

.collapse-close {
  width: 100%;
  text-align: center;
  border-radius: 0px;
  border-top-right-radius: var(--default-radius);
  border-top-left-radius: var(--default-radius);
  transform: rotate(180deg);
  transition: all 0.9s ease-out;
  float: none;
}

.collapse-img {
  width: 22px;
}

/* typography */

.ant-typography-secondary {
  color: var(--secondary-color) !important;
  font-weight: normal !important;
}

/* LABEL */

.ant-empty-description {
  color: var(--secondary-color) !important;
}

/* INPUTS */

/* .ant-select-tree > div{
    background-color: var(--background)  !important;
} */

.ant-form-item-label label {
  font-style: var(--font-style) !important;
  font-weight: 500 !important;
  color: var(--primary-color) !important;
}

.ant-input {
  border-radius: var(--default-radius);
  font-weight: normal;
  background-color: var(--background-input) !important;
  color: var(--primary-color) !important;
}

.ant-input-password {
  border-radius: var(--default-radius) !important;
  background: var(--background-input);
}

.ant-input-password input {
  background-color: transparent !important;
}

.ant-input::placeholder {
  color: var(--secondary-color) !important;
}

.ant-picker-input>input::placeholder {
  color: var(--secondary-color) !important;
}

.ant-select-selection-placeholder {
  color: var(--secondary-color) !important;
}

.ant-input-number,
.ant-input-number-in-form-item {
  border-radius: var(--default-radius) !important;
  background: var(--background) !important;
}

.ant-input-number-input {
  border-radius: var(--default-radius) !important;
  color: var(--secondary-color) !important;
}

.ant-input-status-success {
  color: var(--secondary-color) !important;
}

.ant-select-item-option-content * {
  color: var(--secondary-color) !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-select-item {
  color: var(--secondary-color) !important;
}

.ant-select-dropdown,
.ant-tree-select-dropdown,
.ant-select-tree {
  background-color: var(--background) !important;
}

.ant-select-selector,
.ant-picker {
  background-color: var(--background-input);
}

.ant-select-selection-item {
  color: var(--secondary-color);
}

.ant-select-tree-title {
  color: var(--secondary-color);
}

.ant-select-tree-node-selected {
  background-color: var(--background-secondary);
}

.ant-select>*:not(.ant-select-arrow) {
  background-color: var(--background-input) !important;
}

.ant-picker {
  border-radius: var(--default-radius) !important;
}

.ant-picker-input input {
  color: var(--secondary-color) !important;
}

.ant-picker-header {
  color: var(--primary-color) !important;
  background: var(--background) !important;
}

.ant-picker-content thead tr th {
  color: var(--secondary-color) !important;
  background: var(--background) !important;
}

.ant-picker-content tbody tr td {
  color: var(--secondary-color) !important;
  background: var(--background) !important;
}

.ant-picker-time-panel-cell-inner {
  color: var(--secondary-color) !important;
}

.ant-picker-datetime-panel {
  background: var(--background) !important;
}

.ant-picker-active-bar {
  border-bottom: 2px solid var(--primary-color) !important;
}

.ant-picker-cell .ant-picker-cell-in-view {
  background: var(--secondary-color) !important;
}

.ant-picker-cell-range-start>* {
  background: var(--primary-color) !important;
}

.ant-picker-cell-selected>* {
  background: var(--primary-color) !important;
}

.ant-picker-time-panel-column .ant-picker-cell-inner {
  color: var(--secondary-color) !important;
  background: var(--background) !important;
}

.ant-picker-panels,
.ant-picker-footer {
  background: var(--background) !important;
}

.ant-switch-inner {
  background: var(--primary-color) !important;
}

/* BTN */

button:disabled {
  background: var(--secondary-color-rgba) !important;
}

.btn-menu-local {
  background: var(--background) !important;
}

.ant-btn {
  border-radius: var(--default-radius);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: var(--background);
}

.ant-btn-primary {
  border-radius: var(--default-radius);
  background-color: var(--primary-color);
  color: var(--text-color-btn);
}

.ant-btn-primary:hover,
.ant-btn-primary:disabled {
  background-color: var(--primary-color);
}

/* .ant-btn-primary:focus {
    background-color: var(--primary-color);
}

.ant-btn-primary:hover {
    background-color: var(--primary-color);
} */

.btn-img-icon {
  width: 14px;
}

.btn-img-icon:hover {
  width: 14px;
}

.ant-picker-ok .ant-btn {
  background: var(--primary-color) !important;
  color: var(--text-color-btn);
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid var(--primary-color) !important;
}

/* TABLE */

.ant-table-container {
  background-color: var(--background) !important;
  border-radius: 0 !important;
}

.ant-table-thead .ant-table-cell {
  color: var(--primary-color) !important;
  background: none !important;
  border: none !important;
  border-bottom: 1px solid var(--secondary-color-rgba) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: var(--background) !important;
}

.ant-table-tbody {
  color: var(--secondary-color);
}

.ant-table-tbody td {
  border: none !important;
}

.dropdown-select {
  color: var(--secondary-color);
}

.ant-table-selection-column .ant-table-cell {
  color: var(--secondary-color) !important;
}

.ant-pagination-item-active {
  border: 1px solid var(--primary-color) !important;
}

.ant-pagination-item a {
  color: var(--secondary-color) !important;
}

.ant-table-row-selected td {
  background-color: var(--background-secondary) !important;
}

.ant-table-row {
  cursor: pointer !important;
}

.ant-table-expanded-row-fixed {
  background-color: var(--background) !important;
}

/* Add the following code to your global CSS file or style tag */
.ant-table-body {
  overflow-y: hidden !important;
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color-rgba) var(--background);
}

.ant-table-body:hover {
  scrollbar-color: var(--primary-color) var(--background-secondary);
}

/*icons*/

/* .export {
    margin-top: -1em !important;
}  */

.ant-dropdown-trigger .anticon-down svg {
  width: 20px !important;
  height: 20px !important;
}

.anticon * {
  fill: var(--primary-color) !important;
}

.anticon-bell {
  font-size: 1.5em !important;
}

.anticon-bell * {
  fill: var(--primary-text-color) !important;
}

.anticon-user * {
  fill: var(--primary-text-color) !important;
}

.ant-dropdown-menu {
  background-color: var(--background) !important;
}

.ant-dropdown-menu-title-content {
  color: var(--secondary-color) !important;
}

/*diversos*/

.reset {
  margin: 0px !important;
  padding: 0px !important;
}

.padding {
  padding: var(--default-padding) !important;
}

.ant-descriptions * {
  color: var(--secondary-text-color) !important;
}

/* DASHBOARD */

.div-dash-itens {
  /* padding: 10px; */
}

.chart>* {
  max-width: 100% !important;
}

.apexcharts-canvas,
.apexcharts-canvas svg {
  width: 100% !important;
}

.dashboard-select .ant-select-selector {
  border: none !important;
  /* background-color: var(--background-secondary) !important; */
}

.notification {
  height: 90vh;
}

.smile-text {
  margin-left: 5px;
  font-weight: bold;
}

.card-top4 {
  padding: 0.5em 0em;
}

.card-top4 .smile-text {
  font-size: 12px;
}

.smile-img-top {
  width: 1.5em;
}

.avg-img {
  width: 35px;
  margin-top: 5px;
}

/* modal */

.ant-notification-notice {
  background-color: var(--background) !important;
}

.ant-notification-notice-message {
  color: var(--primary-text-color) !important;
}

.ant-notification-notice-description {
  color: var(--secondary-color);
}

.ant-message-notice-content {
  background-color: var(--background) !important;
  color: var(--secondary-color);
}

.ant-modal-confirm-title {
  color: var(--primary-text-color) !important;
}

.ant-modal-confirm-content {
  color: var(--secondary-text-color) !important;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }

  50% {
    transform: translateY(4%);
  }

  65% {
    transform: translateY(-2%);
  }

  80% {
    transform: translateY(2%);
  }

  95% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(0%);
  }
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: var(--background) !important;
  border-radius: var(--default-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: var(--box-shadow);
}

.popup li .ant-btn {
  border: none;
}

.div-category-icon {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: var(--box-shadow);
  text-align: center;
  padding: var(--default-padding);
  border-radius: var(--default-radius);
}

.div-category-icon img {
  box-shadow: var(--box-shadow);
}

.ant-upload-list-item-name {
  color: var(--secondary-text-color);
}

/* checkbox */

.ant-checkbox-inner {
  background: var(--primary-color) !important;
  border: none !important;
}

.ant-checkbox-inner:hover {
  border: none !important;
}

.ant-modal-title {
  color: var(--primary-text-color) !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
  font-size: 1.5em !important;
  line-height: 22px !important;
  padding-left: 15px !important;
}

.ant-spin-text {
  color: var(--primary-color) !important;
}

.ant-spin-dot-holder {
  color: var(--primary-color) !important;
}